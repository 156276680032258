import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import './partner.style.css';

const Partners = () => {
  const partnersData = useStaticQuery(graphql`
  {
    allContentfulPartners {
      nodes {
        title
        partnersLogos {
          logo {
              title
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  const partnersContent = partnersData.allContentfulPartners.nodes;

  return (
    <section className="flex flex-wrap mt-8 p-0 relative w-full z-2 partners">
      <div className="container xl:px-[3.3rem] mx-auto pt-4 sm:px-3">
        {partnersContent.map(({ title, partnersLogos: { logo } }) => (
          <div key={title} className="row">
            <div className="col-12">
              <div className="bg-secondary shadow-1 w-full inner">
                <ul className="items-center flex m-0 p-0 text-center ssm:flex-wrap">
                  <li className="hidden md:flex m-0 list-none first:border-r first:border-solid first:border-border first:py-md first:px-wide" data-wow-offset="50">
                    <div className="inline-block text-md-0 font-bold m-0">{title}</div>
                  </li>
                  {logo.map(({ gatsbyImageData, title: logoTitle }) => (
                    <li
                      key={logoTitle}
                      className="flex-1 list-none m-0 md:px-0 md:w-1/3 ssm:p-8"
                      data-wow-offset="50"
                      data-wow-delay=".5s"
                    >
                      <figure className="mx-auto lg:w-md w-full transition-all delay-75 duration-1 ease-in-out">
                        <GatsbyImage image={gatsbyImageData} alt={logoTitle} className="flex w-full" />
                      </figure>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Partners;
